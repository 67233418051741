/*
 *
 * GroupsPage constants
 *
 */

enum ActionTypes {
  DEFAULT_ACTION = 'app/GroupsPage/DEFAULT_ACTION',
  FETCH_GROUPS_REQUEST = 'app/GroupsPage/FETCH_GROUPS_REQUEST',
  FETCH_GROUPS_SUCCESS = 'app/GroupsPage/FETCH_GROUPS_SUCCESS',
  FETCH_GROUPS_FAILURE = 'app/GroupsPage/FETCH_GROUPS_FAILURE',
  APPLY_FILTER = 'app/GroupsPage/APPLY_FILTER',
}

export default ActionTypes;
