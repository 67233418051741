/*
 *
 * GroupsPage reducer
 *
 */

import { ContainerActions, ContainerState } from './types';

import ActionTypes from './constants';

export const initialState: ContainerState = {
  groups: {
    data: [],
    loading: true,
  },
  filter: {
    query: '',
    permissions: [],
  },
};

function groupsPageReducer(state: ContainerState = initialState, action: ContainerActions) {
  const { type, payload } = action as any;
  switch (type) {
    case ActionTypes.FETCH_GROUPS_SUCCESS:
      return {
        ...state,
        groups: {
          data: payload,
          loading: false,
        },
      };
    case ActionTypes.APPLY_FILTER:
      return {
        ...state,
        filter: {
          ...payload,
        },
      };
    default:
      return state;
  }
}

export default groupsPageReducer;
