/*
 *
 * GroupsPage actions
 *
 */

import { action, createAsyncAction } from 'typesafe-actions';

import ActionTypes from './constants';

export const defaultAction = () => action(ActionTypes.DEFAULT_ACTION);

export const applyFilter = (data: any) => action(ActionTypes.APPLY_FILTER, data);

export const groups = createAsyncAction(
  ActionTypes.FETCH_GROUPS_REQUEST,
  ActionTypes.FETCH_GROUPS_SUCCESS,
  ActionTypes.FETCH_GROUPS_FAILURE,
)<void, {}, Error>();
