import { call, fork, put, take } from 'redux-saga/effects';

import ActionTypes from './constants';
import { buyer as buyerActions } from 'containers/MainLayout/actions';
import { getBuyer } from 'utils/apollo';
import { groups as groupsActions } from './actions';

function* initDataFlow() {
  yield put(groupsActions.request());
}

function* getGroups() {
  while (true) {
    yield take(ActionTypes.FETCH_GROUPS_REQUEST);
    const response = yield call(getBuyer);
    if (!response.errors) {
      yield put(buyerActions.success(response));
      yield put(groupsActions.success(response.userGroups));
    }
  }
}

// Individual exports for testing
export default function* groupsPageSaga() {
  // See example in containers/HomePage/saga.js
  yield fork(initDataFlow);
  yield fork(getGroups);
}
