/**
 *
 * GroupFilter
 *
 */

import * as React from 'react';

import { Col, Input, Row } from 'antd';

import { PermissionGroup } from 'utils/permissionMap';
import Select from 'components/Select/Select';
import { debounce } from 'lodash';
import messages from './messages';
import styled from 'styled-components';
import translations from 'translations';

// import styled from 'styles/styled-components';
const { Search } = Input;

interface OwnProps {
  filter: any;
  onSearch: (data) => void;
  permissionGroups: PermissionGroup[];
}

const Container = styled.div`
  .ant-btn.ant-input-search-button {
    font-size: 24px;
    padding-left: 12px;
    padding-right: 12px;
  }
  .ant-input-affix-wrapper .ant-input:not(:first-child) {
    padding-left: 50px;
  }
`;
class GroupFilter extends React.Component<OwnProps> {
  public state = {
    searchBy: this.props.filter,
  };

  public onApplySearch = debounce((data) => {
    this.props.onSearch(data);
  }, 1600);

  public onChange = (key) => (event) => {
    this.setState({ searchBy: { ...this.state.searchBy, [key]: event.target ? event.target.value : event } });

    this.onApplySearch.cancel();
    this.onApplySearch({
      ...this.state.searchBy,
      [key]: event.target ? event.target.value : event,
    });
  };

  public render() {
    const { searchBy } = this.state;
    return (
      <Container>
        <Row gutter={20}>
          <Col span={8}>
            <Search
              enterButton
              placeholder={translations(messages.searchGroup)}
              value={searchBy.query}
              onChange={this.onChange('query')}
              size="large"
            />
          </Col>
          <Col span={16}>
            <Select
              style={{ width: '100%' }}
              placeholder={translations(messages.filterByPermissions)}
              value={searchBy.permissions}
              onChange={this.onChange('permissions')}
              mode="multiple"
              items={this.props.permissionGroups.map((p) => ({ value: p.id, label: p.title }))}
              allowClear
              size="large"
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

export default GroupFilter;
