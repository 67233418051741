/**
 *
 * GroupsPage
 *
 */

import * as React from 'react';

import { ContainerState, RootState } from './types';
import { Dispatch, compose } from 'redux';

import Button from 'components/TrackingComponents/Button';
import { FormattedMessage } from 'react-intl';
import GroupFilter from './GroupFilter';
import GroupTable from './GroupTable';
import { Helmet } from 'react-helmet';
import styled from 'styles/styled-components';
import { applyFilter } from './actions';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';
import messages from './messages';
import { permissionGroups } from 'utils/permissionMap';
import { push } from 'connected-react-router';
import reducer from './reducer';
import saga from './saga';
import selectGroupsPage from './selectors';
import translations from 'translations';
import { uniq } from 'lodash';

interface OwnProps {
  groupspage: ContainerState;
  userGroups: [];
}

interface StateProps {}

interface DispatchProps {
  gotoCreateGroup: () => void;
  applyFilter: (data) => void;
  gotoGroupDetails: (data) => void;
}

type Props = StateProps & DispatchProps & OwnProps;

const GroupFilterWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 18px;
  & > div {
    flex-grow: 1;
  }
`;

export class GroupsPage extends React.PureComponent<Props> {
  public render() {
    const { filter, groups } = this.props.groupspage;
    const groupsResult =
      !filter.query && !filter.permissions.length
        ? groups.data
        : groups.data
            .filter((group) => {
              if (filter.query) {
                return group.name.toLowerCase().indexOf(filter.query.toLowerCase()) > -1;
              }
              if (filter.permissions.length) {
                let foundPermissionCounter = 0;
                const fullPermissions = group.permissions.map((permission) => permission.action);

                const selectedPermissions = uniq(
                  permissionGroups
                    .filter((pg) => filter.permissions.indexOf(pg.id) > -1)
                    .reduce((prev, crr) => prev.concat(crr.permissions), [] as string[]),
                );

                fullPermissions.forEach((action: string) => {
                  if (selectedPermissions.indexOf(action) > -1) {
                    foundPermissionCounter += 1;
                  }
                });
                return foundPermissionCounter === selectedPermissions.length;
              }
              return false;
            })
            .sort((a, b) => a.name.toUpperCase().localeCompare(b.name.toUpperCase()));
    return (
      <React.Fragment>
        <Helmet>
          <title>{`${translations(messages.header)} | Nhà Cung Cấp Kamereo`}</title>
          <meta name="description" content={`${translations(messages.header)}`} />
        </Helmet>
        {/* <Row type="flex" justify="space-between" align="middle">
          <PageHeader><FormattedMessage {...messages.header} /></PageHeader>
        </Row> */}
        <GroupFilterWrapper>
          <GroupFilter filter={filter} permissionGroups={permissionGroups} onSearch={this.props.applyFilter} />
          <Button
            trackingCategory="User Management | Button"
            trackingAction="Invite Colleagues"
            type="primary"
            onClick={this.props.gotoCreateGroup}
            size="large"
          >
            <FormattedMessage {...messages.createGroup} />
          </Button>
        </GroupFilterWrapper>
        <GroupTable groups={groupsResult} loading={false} onGotoGroupDetails={this.props.gotoGroupDetails} />
      </React.Fragment>
    );
  }
}

const mapStateToProps = createStructuredSelector<RootState, StateProps>({
  groupspage: selectGroupsPage(),
});

function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps): DispatchProps {
  return {
    applyFilter: (data: any) => dispatch(applyFilter(data)),
    gotoCreateGroup: () => dispatch(push('/groups/0')),
    gotoGroupDetails: (groupId: string) => dispatch(push(`/groups/${groupId}`)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

// <OwnProps> restricts access to the HOC's other props. This component must not do anything with reducer hoc
const withReducer = injectReducer<OwnProps>({ key: 'groupsPage', reducer: reducer });
// <OwnProps> restricts access to the HOC's other props. This component must not do anything with saga hoc
const withSaga = injectSaga<OwnProps>({ key: 'groupsPage', saga: saga });

export default compose(withReducer, withSaga, withConnect)(GroupsPage);
